import * as React from "react";
import { Helmet } from "react-helmet";
import ContactForm from "../components/contact-form/ContactForm";
import Hero from "../components/hero/Hero";
import Features from "../components/features/Features";
import TextBlock from "../components/text-block/TextBlock";

// markup
const IndexPage = () => {
    return (
        <>
            <Helmet>
                <title>StaffPanda</title>
            </Helmet>

            <Hero />
            <TextBlock />
            <Features />
            <ContactForm />
        </>
    );
};

export default IndexPage;
