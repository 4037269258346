import React, { useEffect, useRef } from "react";
import { motion, useInView } from "framer-motion";

import "./text-reveal.scss";

interface Props {
    type: "h1" | "h2" | "h3" | "h3-tagline" | "body";
    visibleBg?: "primary" | "dark-1" | "dark-2" | "white" | "none";
    coverBg?: "primary" | "dark-1" | "dark-2" | "white";
    className?: string;
    children: React.ReactNode;
}

const TextReveal = (props: Props) => {
    const { visibleBg = "none", coverBg = "white", } = props;

    const ref = useRef<HTMLDivElement>(null);
    const isInView = useInView(ref);

    return (
        <div ref={ref} className={`text-reveal text-reveal--${props.type} ` + (props.className || "")}>
            <span>
                <motion.span
                    animate={{ x: isInView ? "100%" : "0%", }}
                    className={`text-reveal__cover-bg bg-${coverBg}`}
                    transition={{ delay: 0.5, duration: 0.5, }}>
                    {props.children}
                </motion.span>
                <span className={`text-reveal__content bg-${visibleBg}`}>
                    {props.children}
                </span>
            </span>
        </div>
    );
};

export default TextReveal;
 