import React from "react";
import Paragraphs from "../typography/Paragraph";
import TextReveal from "../typography/TextReveal";
import data from "./features-data";

import "./features.scss";

const Features = () => {
    return (

        <section id="features" className="bg-dark-1" data-midnight="white">
            <h2>
                <TextReveal type="h1" coverBg="primary">
                Features
                </TextReveal>
            </h2>

            {data.map((card, i) => {
                const Illustration = card.image;
                return (
                    <div id={card.id} key={i} className="feature">
                        <div data-animation-container className="feature__text">
                            <h3 className="title-style text-color-4">
                                <TextReveal type="h3" coverBg="white">
                                    {card.title_en}
                                </TextReveal>
                            </h3>
                            <TextReveal type="h3-tagline" coverBg="primary" visibleBg="primary">
                                {card.pre_title_en}
                            </TextReveal>
                            <Paragraphs>
                                {card.description_en}
                            </Paragraphs>
                        </div>
                        <div className="feature__illustration">
                            <div>
                                {typeof Illustration === "string"
                                    ? <img src={Illustration} />
                                    : <Illustration />
                                }
                            </div>
                        </div>
                    </div>
                );
            })}
        </section>
    );
};

export default Features;
