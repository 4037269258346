import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";

import "./paragraph.scss";

interface Props {
    children: React.ReactNode;
}

const hidden = {
    y: "50%",
    opacity: 0,
};

const active = {
    y: "0%",
    opacity: 1,
};

const Paragraphs = (props: Props) => {
    const children = Array.isArray(props.children) ? props.children : [props.children];

    const ref = useRef<HTMLDivElement>(null);
    const isInView = useInView(ref);

    return (
        <div ref={ref}>
            {children.map((child, i) => (
                <motion.p
                    key={i}
                    animate={isInView ? active : hidden}
                    className="paragraph"
                    transition={{  duration: 0.5, }}>
                    {child}
                </motion.p>

            ))}
        </div>
    );
};

export default Paragraphs;
 