import React from "react";
import Paragraphs from "../typography/Paragraph";
import TextReveal from "../typography/TextReveal";

import "./text-block.scss";

import Svg from "./automation.svg";

const TextBlock = () => {
    return (
        <>
            <section id="down" className="bg-dark-1 text-block">
                <div className="text-block__bg bg-dark-2" />
                <div className="text-block__illustration">
                    <Svg />
                </div>
                <div className="text-block__text">
                    <TextReveal type="h2" coverBg="dark-2" visibleBg="dark-2">
                        Why do something
                    </TextReveal>
                    <TextReveal type="h2" coverBg="primary" visibleBg="primary">
                        that can be automated?
                    </TextReveal>
                    <div style={{ marginBottom: "1rem", }} />
                    <Paragraphs>
                        <span>
                            That is the driving philosophy behind our software.
                        </span>
                        <span>
                            After working closely with secondment and temp agencies, we realized that many of their day-to-day tasks could be handled more efficiently by software. Not only are these tasks often tedious, but the lack of a centralized source of information can lead to (expensive!) misunderstandings.
                        </span>
                        <span>
                            That{"'"}s why we created StaffPanda –  an app that not only streamlines tedious tasks but also provides a platform that ensures that everyone, including you, your clients, and your workers, has access to the information they need. 
                        </span>
                    </Paragraphs>
                </div>
            </section>

        </>
    );
};

export default TextBlock;
