export interface IServiceCard {
    id: string;
    title_en: string;
    title_nl: string;
    description_en: string;
    description_nl: string;
    pre_title_en: string;
    pre_title_nl: string;
    image: string | React.FunctionComponent<React.SVGAttributes<SVGElement>>;
}

import Reviews from "./svg/reviews.svg";
import Autonomy from "./svg/autonomy.svg";
import Placements from "./svg/placements.svg";
import WhiteLabel from "./svg/whitelabel.svg";
import Billing from "./svg/billing.svg";

const data: IServiceCard[] = [
    {
        id: "placements",
        pre_title_en: "Let us do the thinking for you",
        pre_title_nl: "Let us do the thinking for you",
        title_en: "Optimized placements",
        title_nl: "Optimized placements",
        description_en: "We know how much of a juggling act candidate placements can be, which is why we’ve developed an algorithm to streamline this process. All you have to do is click a button, and we’ll give you a suggestion of how to best place your candidates according to your criteria.",
        description_nl: "We know how much of a juggling act candidate placements can be, which is why we’ve developed an algorithm to streamline this process. All you have to do is click a button, and we’ll give you a suggestion of how to best place your candidates according to your criteria.",
        image: Placements,
    },
    {
        id: "autonomy",
        pre_title_en: "Make time for the things that matter",
        pre_title_nl: "Make time for the things that matter",
        title_en: "Flexible Autonomy",
        title_nl: "Flexible Autonomy",
        description_en: "Adjust the autonomy levels of individual clients and candidates, giving them the exact amount of control over shift planning that you desire. You can allow clients to create assignments and approve candidate applications, and enable candidates to apply directly to assignments without your approval, freeing you up to focus on scaling your business.",
        description_nl: "Adjust the autonomy levels of individual clients and candidates, giving them the exact amount of control over shift planning that you desire. You can allow clients to create assignments and approve candidate applications, and enable candidates to apply directly to assignments without your approval, freeing you up to focus on scaling your business.",
        image: Autonomy,
    },
    {
        id: "reviews",
        pre_title_en: "Always in the loop",
        pre_title_nl: "Always in the loop",
        title_en: "Review system",
        title_nl: "Review system",
        description_en: "Don’t let hard work go unnoticed! With our review system you get immediate feedback from your clients, so you always know exactly how your candidates are doing. Use the system to reward your most hardworking employees, and to identify potential issues before they escalate. ",
        description_nl: "Don’t let hard work go unnoticed! With our review system you get immediate feedback from your clients, so you always know exactly how your candidates are doing. Use the system to reward your most hardworking employees, and to identify potential issues before they escalate. ",
        image: Reviews,
    },
    {
        id: "billing",
        pre_title_en: "Handle payments in seconds",
        pre_title_nl: "Handle payments in seconds",
        title_en: "Automated billing",
        title_nl: "Automated billing",
        description_en: "No need to even fill out as much as an invoice number! Your clients will be billed automatically, and all payments can be handled via the app.",
        description_nl: "No need to even fill out as much as an invoice number! Your clients will be billed automatically, and all payments can be handled via the app.",
        image: Billing,
    },
    {
        id: "whitelabel",
        pre_title_en: "Your brand on web & mobile",
        pre_title_nl: "Your brand on web & mobile",
        title_en: "White Label",
        title_nl: "White Label",
        description_en: "With our white label option, you can customize our app with your own branding, making it look and feel like your very own platform. Make you presence known on iOS, Android and the web! ",
        description_nl: "With our white label option, you can customize our app with your own branding, making it look and feel like your very own platform. Make you presence known on iOS, Android and the web! ",
        image: WhiteLabel,
    }
];

export default data;
