import React, { FormEventHandler, useState } from "react";
import Paragraphs from "../typography/Paragraph";
import TextReveal from "../typography/TextReveal";
import TextField from "@mui/material/TextField";

import "./contact-form.scss";

import Grid from "@mui/material/Grid";
import Button from "../button/Button";
import Alert from "@mui/material/Alert";
import { AlertTitle } from "@mui/material";


const ContactForm = () => {
    const [state, setState] = useState<"success" | "busy" | "error" | "draft">("draft");

    const handleSubmit: FormEventHandler = (e) => {
        e.preventDefault();

        const myForm = e.target;
        const formData = new FormData(myForm as HTMLFormElement);

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded", },
            body: new URLSearchParams(formData as Record<string, any>).toString(),
        }).then(() => {
            setState("success");
        }).catch(() => {
            setState("error");
        });
    };

    return (
        <section id="contact" className="bg-dark-2 contact-form">
            <div className="contact-form__inner">

                <div className="contact-form__header">
                    <h2>
                        <TextReveal type="h2" coverBg="primary" >
                                Work with us
                        </TextReveal>
                    </h2>

                    <Paragraphs>
                        We are busy getting our software ready for public distribution, but we are are open to collaboration with individual businesses.
                    </Paragraphs>
                </div>


                {(state !== "success") &&
                    <form method="POST" name="contact" data-netlify="true" onSubmit={handleSubmit}>
                        <input type="hidden" name="form-name" value="contact" />

                        <Grid container spacing={2}>
                            {state === "error" &&
                                <Grid item xs={12} md={12}>
                                    <Alert severity="error">
                                        <AlertTitle>
                                            Something went wrong!
                                        </AlertTitle>
                                        Try again later, or reach out to us directly at hello@staffpanda.nl.
                                    </Alert>
                                </Grid>
                            }
                            <Grid item xs={12} md={4}>
                                <TextField label="Your name" name="name" required fullWidth={true} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField label="Your business" name="business" required fullWidth={true} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField label="Email" name="email" type="email" required fullWidth={true} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField label="Message" name="message" multiline={true} minRows={5} fullWidth={true} required />
                            </Grid>
                            <Button isSubmit={true}>
                                {state === "busy" ? "Sending..." : "Submit"}
                            </Button>
                        </Grid>
                    </form>
                }

                {state === "success" &&
                        <Alert severity="success">
                            <AlertTitle>
                                Thank you for your message!
                            </AlertTitle>
                            We{"'"}ll be in touch with you soon.
                        </Alert>
                }
            </div>
        </section>
    );
};

export default ContactForm;
