import React from "react";
import { Link } from "gatsby";

import "./button.scss";

interface Props {
    id?: string;
    children: string | React.ReactNode;
    classes?: string;
    type?: "arrow" | "border";
    backgroundColor?: "dark" | "transparent";
    color?: "primary" | "default";
    href?: string;
    isSubmit?: boolean;
}

const Button = (props: Props) => {
    const text = props.children;

    const {
        type = "border",
        // backgroundColor = "transparent",
        color = "default",
        classes = "",
    } = props;

    const buttonType = type + "-btn";
    const buttonClass = color === "primary" ? "border-btn-red" : "";
    const className = `${buttonType}-box ${buttonClass} pointer-large  ${classes}`;

    const content = (
        <>
            <span className={`${buttonType}-inner`}>
                <a className={buttonType} data-text={text}>{text}</a>
            </span>
        </>
    );

    if (!props.href) {
        return (
            <button id={props.id} className={className} type={props.isSubmit ? "submit" : undefined}>
                {content}
            </button>
        );
    }

    if (props.href.match("#")) {
        return (
            <a id={props.id} className={className} href={props.href}>
                {content}
            </a>
        );
    }

    return (
        <Link id={props.id} className={className} to={props.href}>
            {content}
        </Link>
    );
};

export default Button;
 