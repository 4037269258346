import React from "react";
import Button from "../button/Button";

import "./hero.scss";

import Svg from "./hero-illustration.svg";
import TextReveal from "../typography/TextReveal";
import Paragraphs from "../typography/Paragraph";

const Hero = () => {

    return (
        <section id="up" className="bg-dark-1 hero">
            <div className="hero__inner">
                <div className="hero__illustration">
                    <Svg />
                </div>
                <div className="hero__text">
                    <h1>
                        <TextReveal type="h2" coverBg="dark-2" visibleBg="dark-1">
                                Scale your
                        </TextReveal>
                        <TextReveal type="h1" coverBg="primary" visibleBg="primary">
                                Staffing business
                        </TextReveal>
                    </h1>
                    <Paragraphs>
                                Automate time-consuming processes so you can focus on growth.
                    </Paragraphs>
                    <div>
                        <Button href="#down">Learn how</Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
